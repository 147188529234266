import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { nanoid } from 'nanoid';
import { EventState, resetEvent } from 'src/app/store/eventSlice';
import store, { AppThunk, RootState } from 'src/app/store/index';
import { resetOrder, reviveCachedOrder, selectOrder } from 'src/app/store/orderSlice';
import { getEnvironment } from 'src/app/utils/config';
import { RouteHelper } from 'src/app/utils/RouteHelper';
import { setThemeStyle } from 'src/app/utils/theme';
import { environment, queryStringToObject, retrievePartnerWebsiteUrl } from 'src/app/utils/utils';
import { Config, ConfigEntity } from 'src/data/models/Config';
import { ConfigCheckout, ConfigCheckoutEntity } from 'src/data/models/ConfigCheckout';
import { PackageType } from 'src/data/models/Order';
import * as Cache from 'src/data/services/cache';
import { fetchPartnerConfig } from 'src/data/services/config';
import { fetchConfigCheckout } from 'src/data/services/configCheckout';
import { setTravelwareApiConfig } from 'src/data/services/fetchUrl';
import { mapGateways } from 'src/data/services/payments';
import { i18nConfig } from 'src/i18n';
import { getAppLocale } from '../hooks/use-locale';

export type Metadata = {
    utmSource?: string;
    utmCampaign?: string;
    utmMedium?: string;
    refUtmSource?: string;
    refUtmCampaign?: string;
    refUtmMedium?: string;
};

export type BrowserSession = {
    urlProvidedGtmId?: string;
};

export type Session = {
    id: string;
    eventId: string;
    baseTicketCategoryId: string | null;
    preferredPackageType: string | null;
    orderId: string | null;
    orderSecret: string | null;
};

export type ExperimentVariantType = {
    id: string;
    variant?: string;
};

export type AppState = {
    referrerUrl: URL | null;
    partnerConfig: ConfigEntity | null;
    partnerConfigCheckout: ConfigCheckoutEntity | null;
    appInitialized: boolean;
    initializedSession: boolean;
    session: Session | null;
    metadata: Metadata;
    browserSession: BrowserSession;
    b2bMode: boolean;
    variants?: ExperimentVariantType[];
};

const initialState: AppState = {
    referrerUrl: null,
    partnerConfig: null,
    partnerConfigCheckout: null,
    appInitialized: false,
    initializedSession: false,
    session: null,
    metadata: {},
    browserSession: {},
    b2bMode: false,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setGoogleOptimizeVariants: (
            state,
            action: PayloadAction<{ variants: ExperimentVariantType[] }>
        ) => {
            state.variants = action.payload.variants;
        },
        mergeMetadata: (state, action: PayloadAction<Metadata>) => {
            state.metadata = { ...state.metadata, ...action.payload };
        },
        mergeBrowserSession: (state, action: PayloadAction<BrowserSession>) => {
            state.browserSession = { ...state.browserSession, ...action.payload };
        },
        setReferrerUrl: (state, action: PayloadAction<URL | null>) => {
            state.referrerUrl = action.payload;
        },
        setPartnerConfig: (state, action: PayloadAction<ConfigEntity>) => {
            state.partnerConfig = action.payload;
        },
        setPartnerConfigCheckout: (state, action: PayloadAction<ConfigCheckoutEntity>) => {
            state.partnerConfigCheckout = action.payload;
        },
        setAppInitialized: (state, action: PayloadAction<boolean>) => {
            state.appInitialized = action.payload;
        },
        setInitializedSession: (state, action: PayloadAction<boolean>) => {
            state.initializedSession = action.payload;
        },
        setSessionOrder: (
            state,
            action: PayloadAction<{ orderId: string; orderSecret?: string }>
        ) => {
            if (!state.session) {
                Sentry.captureException('Tried to initialize order session without a session');
                return;
            }

            state.session.orderId = action.payload.orderId;
            state.session.orderSecret = action.payload.orderSecret || null;
        },
        createSession: (
            state,
            action: PayloadAction<{
                id: string;
                eventId: string;
                baseTicketCategoryId?: string | null;
                preferredPackageType?: PackageType;
            }>
        ) => {
            state.session = {
                id: action.payload.id,
                eventId: action.payload.eventId,
                baseTicketCategoryId: action.payload.baseTicketCategoryId || null,
                preferredPackageType:
                    action.payload.preferredPackageType || PackageType.TICKET_ONLY,
                orderId: null,
                orderSecret: null,
            };
        },
        deleteSession: (state) => {
            state.session = null;
        },
        setB2BMode: (state, action: PayloadAction<boolean>) => {
            state.b2bMode = action.payload;
        },
    },
});

const {
    setReferrerUrl,
    setPartnerConfig,
    setPartnerConfigCheckout,
    setAppInitialized,
    setInitializedSession,
    createSession,
    deleteSession,
    setSessionOrder,
    mergeMetadata,
    mergeBrowserSession,
    setB2BMode,
    setGoogleOptimizeVariants,
} = appSlice.actions;

export { setB2BMode, setGoogleOptimizeVariants, setSessionOrder };

export const initializeBrowserSession = (): AppThunk => (dispatch, getState) => {
    const queryParams = queryStringToObject(window.location.search);
    const existingBrowserSession = getState().app.browserSession;

    dispatch(
        mergeBrowserSession({
            urlProvidedGtmId: queryParams.gtm_id || existingBrowserSession.urlProvidedGtmId,
        })
    );
};

export const initialize = (): AppThunk => (dispatch) => {
    const queryParams = queryStringToObject(window.location.search);

    dispatch(setAppInitialized(false));
    dispatch(initializeBrowserSession());

    dispatch(
        mergeMetadata({
            utmCampaign: queryParams.utm_campaign?.toString(),
            utmMedium: queryParams.utm_medium?.toString(),
            utmSource: queryParams.utm_source?.toString(),
            refUtmCampaign: queryParams.ref_utm_campaign?.toString(),
            refUtmMedium: queryParams.ref_utm_medium?.toString(),
            refUtmSource: queryParams.ref_utm_source?.toString(),
        })
    );

    const travelwareApiConfig =
        getEnvironment('REACT_APP_ENABLE_BACKEND_PROXY') !== '1'
            ? {
                  baseUrl: getEnvironment('REACT_APP_TW_BASE_URL') || '',
                  key: getEnvironment('REACT_APP_TW_API_KEY'),
                  throughProxy: false,
              }
            : {
                  baseUrl: window.location.origin + '/_TWBP',
                  throughProxy: true,
              };

    setTravelwareApiConfig(travelwareApiConfig);

    Promise.all([fetchPartnerConfig(), fetchConfigCheckout()])
        .then(([partnerConfig, configCheckout]) => {
            dispatch(setPartnerConfig(partnerConfig));
            dispatch(setPartnerConfigCheckout(configCheckout));

            const partnerWebsiteUrl = retrievePartnerWebsiteUrl(
                window.document.referrer,
                partnerConfig.websites,
                partnerConfig.website_url
            );

            dispatch(setReferrerUrl(partnerWebsiteUrl));

            const { cType } = queryParams;
            if (cType === 'b2b' && partnerConfig.is_b2b) dispatch(setB2BMode(true));

            setThemeStyle(configCheckout.theme?.css || '');

            return { partnerName: configCheckout.general.name };
        })
        .then(({ partnerName }) => {
            const locale = getAppLocale();

            return i18nConfig({
                interPolationDefaults: { globalPartnerName: partnerName },
                debugMode: environment() !== 'production',
                locale,
            });
        })
        .then((t) => {
            window.document.title = t('document_title');
            dispatch(setAppInitialized(true));
        })
        .catch((error) => {
            console.error(`Failed to initialize: ${String(error)}`);
        });
};

export const resetSession = () => {
    store.dispatch(resetEvent());
    store.dispatch(resetOrder());
    store.dispatch(deleteSession());
};

export const initializeSession =
    (
        eventId: string,
        options: {
            sessionId?: string | null;
            baseTicketCategoryId?: string | undefined;
            preferredPackageType?: PackageType;
        }
    ): AppThunk =>
    (dispatch, getState) => {
        const currentSession = getState().app.session;
        // Default value of currentSession.baseTicketCategoryId is null, which we want to compare this value to. Hence the fallabck is null
        const categoryId = options?.baseTicketCategoryId ?? null;
        const isOnPaymentPage = window.location.pathname.includes('/payment');

        // If we are in the payment page, we expect to have a booking code
        const shuldResetIfBookingCodeExists = isOnPaymentPage ? false : !!Cache.getBooking();

        const hasTheSameSession =
            currentSession &&
            currentSession.eventId === eventId &&
            currentSession.baseTicketCategoryId === categoryId &&
            currentSession.preferredPackageType?.toLocaleLowerCase() ===
                options?.preferredPackageType?.toLocaleLowerCase();

        if (!hasTheSameSession || shuldResetIfBookingCodeExists) {
            resetSession();
            Cache.removeBookingCode();
            dispatch(
                createSession({
                    id: nanoid(),
                    eventId,
                    baseTicketCategoryId: categoryId,
                    preferredPackageType: options?.preferredPackageType || undefined,
                })
            );
            dispatch(setSessionOrder({ orderId: '', orderSecret: '' }));
        }

        if (selectOrder(getState()) !== null) {
            dispatch(reviveCachedOrder());
        }

        dispatch(setInitializedSession(true));
    };

export const requireActiveSessionOrReset = (eventId: string) => {
    const { session: currentSession } = store.getState().app as AppState;
    const currentEvent = store.getState().event as EventState;
    const currentOrder = selectOrder(store.getState());

    const resetAndRedirect = () => {
        resetSession();
        window.location.href = RouteHelper.getTicketRoute(
            eventId,
            queryStringToObject(window.location.search)
        );
    };

    if (!currentSession) {
        resetAndRedirect();
        return;
    }

    const sessionMatchesEvent =
        currentEvent?.id === currentSession.eventId &&
        currentEvent?.detailCategoryId === currentSession.baseTicketCategoryId;

    if (!sessionMatchesEvent) {
        resetAndRedirect();
        return;
    }

    const sessionMatchesOrder =
        currentOrder &&
        currentOrder.id === currentSession.orderId &&
        currentOrder.baseTicketCatId === currentSession.baseTicketCategoryId;

    if (!sessionMatchesOrder) {
        resetAndRedirect();
    }
};

export const selectIsReady = (state: RootState) => {
    return state.app.appInitialized;
};

export const selectPartnerConfig = createSelector(
    (state: RootState) => state.app.partnerConfig,
    (partnerConfig) => (partnerConfig === null ? null : new Config(partnerConfig))
);

export const selectPartnerConfigCheckout = createSelector(
    (state: RootState) => state.app.partnerConfigCheckout,
    (partnerConfigCheckout) =>
        partnerConfigCheckout === null ? null : new ConfigCheckout(partnerConfigCheckout)
);

export const selectPaymentGateways = createSelector(
    (state: RootState) => selectPartnerConfig(state),
    (partnerConfig) => (partnerConfig?.payment ? mapGateways(partnerConfig?.payment) : null)
);

export const selectReferrerUrl = (state: RootState) => state.app.referrerUrl;
export const selectSession = (state: RootState) => state.app.session;
export const selectInitializedSession = (state: RootState) => state.app.initializedSession;
export const selectBrowserSession = (state: RootState) => state.app.browserSession;
export const selectIsB2BMode = (state: RootState) => state.app.b2bMode;

const selectVariants = (state: RootState) => state.app.variants;
const selectVariantsByProps = (
    state: RootState,
    variant: { experimentIds: string[]; variantId: string }
) => variant;

export const getVariant = createSelector(
    [selectVariants, selectVariantsByProps],
    (variants, variantProps) =>
        variants?.find(
            (variant) =>
                variantProps.experimentIds.includes(variant.id) &&
                variant.variant === variantProps.variantId
        )
);

export default appSlice.reducer;
